<template>
    <v-container fluid>
        <tool-bar :toolbar="toolbar" @insertDialog="openDialog()"></tool-bar>
        <v-simple-table fixed-header :height="tableHeight" class="mainTable">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center simple-table=header">{{ $t('global.name') }}</th>
                        <th class="text-center simple-table=header">{{ $t('homeHeader.status') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.action') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(admin, adminIndex) in fullAdminData" :key="adminIndex">
                        <td class="text-center">{{ admin.user_name }}</td>
                        <td class="text-center"> 
                            <!-- {{ admin.status }} -->
                                <v-chip
                                    class="ma-2"
                                    small
                                    color="#2E7D32"
                                    outlined
                                    >
                                    {{ admin.status.toUpperCase() }}
                                </v-chip>
                        </td>
                        <td class="text-center">
                            <v-btn small icon @click="deleteAdmin(admin)">
                                <v-icon class="delete-file-svg">
                            </v-icon>
                        </v-btn>
                        </td>
                    </tr>
                    
                </tbody>
            </template>
        </v-simple-table>


        <v-row justify-center>
            <v-dialog
                v-model="insertDialog"
                persistent
                width="300"
                @keydown.esc="insertDialog = false"
                scrollable
            >
                <v-form
                    id="Insert"
                    ref="Insert"
                    @submit.prevent="Insert"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <span class="title-headline">{{ $t('global.newFullAdmin') }}</span>
                            <v-spacer></v-spacer>
                            <v-icon color="white" @click="insertDialog = false">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="card-text">
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-autocomplete
                                            v-model="add.user_id"
                                            :items="userData"
                                            item-text="Name"
                                            item-value="UserId"
                                            :label="$t('global.name')"
                                            outlined
                                            required
                                            dense
                                            class="mb-2 required"
                                            persistent-placeholder
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn type="submit" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
    import axios from 'axios';
    import swal from 'sweetalert2';
    import Toolbar from '../includes/Toolbar.vue'
    import { mapState, mapActions } from 'vuex';

    export default {
        components: {
            "tool-bar": Toolbar,
        },

        data: () => ({
            toolbar: {
                title: 'toolbar.homeTitle',
                sub_title: 'toolbar.fullAdminSettings',
                insertDialog: true,
            },
            tableHeight: window.innerHeight - 160,
            insertDialog: false,
            add: {},
        }),
        computed: {
            ...mapState(["tsuikaUserData", "fullAdminData"]),
            userData() {
                return this.tsuikaUserData.filter(res => !this.fullAdminData.some(rec => res.UserId == rec.user_id)) //for actual
            }
        },
        methods: {
            ...mapActions(["getFullAdmin", "getTsuikaUsers"]),
            openDialog() {
                this.insertDialog = true;
            },
            Insert() {
                const findUserData = this.tsuikaUserData.find(res => res.UserId == this.add.user_id); //for actual

                swal
                    .fire({
                    title: "Confirmation",
                    text: `Are you sure to add this user?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Confirm`,
                    denyButtonText: `Cancel`,
                }).then(result => {
                    if(result.isConfirmed) {
                        axios({
                            method: 'post',
                            url: `${window.api}/mysql/insertFullAdmin`,
                            data: findUserData,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        }).then(res => {
                            if(res.data) {
                                this.insertDialog = false;
                                this.getFullAdmin();

                                this.$socket.emit('gcTsuikaData', {
                                    system: 'gc-tsuika',
                                    request: 'accessRightsUpdate',
                                    requestData: this.loggedInUser.user_id,
                                    updateFullAdmin: true,
                                    dispatchType: 'request-local',
                                    port: this.windowLoc.port,
                                    protocol: this.windowLoc.protocol,
                                    hostname: this.windowLoc.hostname,
                                })
                                
                                location.reload(true);
                            }   
                        })
                    }
                })

                
            },
            deleteAdmin(item) {
                swal
                    .fire({
                    title: "Confirmation",
                    text: `Are you sure to delete this record?`,
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Confirm`,
                    denyButtonText: `Cancel`,
                }).then(result => {
                    if(result.isConfirmed) {
                        axios({
                            method: 'post',
                            url: `${window.api}/mysql/deleteAdmin`,
                            data: item,
                            headers: {
                                'x-api-key' : process.env.VUE_APP_HEADERS
                            }
                        }).then(res => {
                            if(res.data) {
                                swal.fire({
                                    position: "center",
                                    icon: "success",
                                    title: "Your data has beed deleted",
                                    showConfirmButton: false,
                                    timer: 1500,
                                }).then(() => {
                                    this.$socket.emit('gcTsuikaData', {
                                        system: 'gc-tsuika',
                                        request: 'accessRightsUpdate',
                                        requestData: this.loggedInUser.user_id,
                                        updateFullAdmin: true,
                                        dispatchType: 'request-local',
                                        port: this.windowLoc.port,
                                        protocol: this.windowLoc.protocol,
                                        hostname: this.windowLoc.hostname,
                                    })
                                    
                                    location.reload(true);
                                });
                                this.getFullAdmin();

                            //     swal
                            //     .fire({
                            //     title: "Are you sure?",
                            //     text: "You won't be able to revert this!",
                            //     icon: "warning",
                            //     showCancelButton: true,
                            //     confirmButtonColor: "#3085d6",
                            //     cancelButtonColor: "#d33",
                            //     confirmButtonText: "Yes, delete it!",
                            //     })
                            //     .then( async (result) => {
                            //     if (result.isConfirmed) {
                            //         swal.fire({
                            //         position: "center",
                            //         icon: "success",
                            //         title: "Your Data has been Deleted",
                            //         showConfirmButton: false,
                            //         timer: 1500,
                            //         });

                            //         await axios({
                            //             method: 'POST',
                            //             url: `${window.api}/mysql/deletEmail/delete`,
                            //             data: item,
                            //             headers: {
                            //                 'x-api-key' : process.env.VUE_APP_HEADERS
                            //             }
                            //         }).then(() => {
                            //             this.getEmail()
                            //         })
                            //     }
                            // });
                            }
                        })
                    }   
                })
                
            }
        },
        created() {
            this.getTsuikaUsers().then(() => {
                this.getFullAdmin();
            })
        },
    }
</script>
<style scoped>
</style>